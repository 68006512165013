import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';

import reducers from './reducer';
import initState from './initState';

const defaultState = {
  api: initState
}

export const initStore = (initialState = defaultState) => {
  return createStore(reducers, initialState,
    composeWithDevTools(
      applyMiddleware(thunkMiddleware, logger)
    )
  );
}
