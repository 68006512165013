import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setKey } from '../redux/action';

class Column extends Component {
  handleClick = () => {
    const { keyCell, setedKey } = this.props;

    if (keyCell === setedKey) {
      this.props.setKey(null);
    } else {
      this.props.setKey(this.props.keyCell);
    }
  }

  render() {
    const { color, keyCell, setedKey } = this.props;
    const style = {
      backgroundColor: `#${color}`,
    };

    if (keyCell === setedKey) {
      style.border = '3px solid rgba(92, 152, 249, .8)';
    }

    return (<li ref={(ref) => this.elem = ref} className={`cell ${keyCell}`} style={style} onClick={this.handleClick}></li>);
  }
}

const mapStateToProps = ({ api }) => {
  return {
    setedKey: api.key
  }
}

export default connect(mapStateToProps, { setKey })(Column);