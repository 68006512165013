// 120 - 43
import axios from 'axios';
import firebase from 'firebase';
export const GETTING_DATA = 'GETTING_DATA';
export const GET_DATA = 'GET_DATA';
export const SEND_DATA = 'SEND_DATA';
export const RECIEVED_DATA ='RECIEVED_DATA';

// const fakeData = {
//   _20_1: "ffffff",
//   _20_10: "b0a1cf",
//   _2_10: "a0a1cf",
//   _3_50: "ff0000"
// }

// const fakeCall = (data) => {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve(data);
//     }, 2000);
//   })
// }

const urlGet = 'https://us-central1-protopixel-7163a.cloudfunctions.net/getPixels';

const gettingData = () => {
  return {
    type: GETTING_DATA
  }
}

export const recievedData = (data) => {
  return {
    type: RECIEVED_DATA,
    data
  }
}

export const changeColor = (color) => {
  return {
    type: 'CHANGE_COLOR',
    color
  }
}

export const setKey = (key) => {
  return {
    type: 'CHANGE_KEY',
    key
  }
}

export const getData = () => {
  // return (dispatch, getState) => {
  //   dispatch(gettingData());
  //   return fakeCall().then(() => {
  //     dispatch(recievedData(fakeData));
  //   });
  // }
  return (dispatch, getState) => {
    dispatch(gettingData());
    return axios.get(urlGet, {
      headers: { 'access-control-allow-origin': '*' },
    })
    .then((res) => {
      dispatch(recievedData(res.data));
    })
  }
}

var config = {
  apiKey: "AIzaSyC-PXRAJDUWe566oOfrZdSkI6ocAc8mYDw",
  authDomain: "protopixel-7163a.firebaseapp.com",
  databaseURL: "https://protopixel-7163a.firebaseio.com"
};
firebase.initializeApp(config);

// Get a reference to the database service
const database = firebase.database();


const handleWebSocket = (data) => {
  return (dispatch, getState) => {
    dispatch(recievedData(data));
  }
}

export const startWebSocket = (dispatch) => {

  database.ref("/pixels/").on("value", snapshot => {
    const dots = snapshot.val();
    dispatch(handleWebSocket(dots));
  });
  return {
    type: 'START_WEBSOCKET'
  }
}



