import React, { Component } from 'react';
import { connect } from 'react-redux';
import Column from './Column';

import { getData } from '../redux/action';

class Row extends Component {

  render() {
    const { columns } = this.props;

    const style = {
      display: 'flex',
      width: '7560px'
    };

    return (
      <ul style={style}>
        {columns}
      </ul>
    );
  }
}

class Controller extends Component {

  componentDidMount() {
    this.props.getData().then((res) => {

    }).catch((err) => {

    })
  }

  isFirst = true;

  _render() {
    const { data } = this.props;
    const grid = [];

    if (!data) {
        return 'Loading...';
    }

    for (let i = 0; i < 43; i++) {
      const newRow = [];
      for (let j = 0; j < 120; j++) {
        const numberKey = `_${j}_${i}`;
        const color = data[numberKey] || '';
        newRow.push(<Column color={color} key={numberKey} keyCell={numberKey}/>);
      }

      grid.push(
        <Row columns={newRow} key={i}/>
      );
    }

    if (this.isFirst) {
      this.isFirst = false;
      setTimeout(() => {
        const elem =  document.querySelector('.color-picker-wrpaper ~ div');
        console.log(elem.scrollWidth, elem.scrollHeight);
        elem.scrollTo(elem.scrollWidth / 2, elem.scrollHeight / 2);
      }, 1500);
    }

    return grid;
  }

  render() {
    return this._render();
  }
}

const mapStateToProps = ({ api }) => {
  return {
    data: api.data
  }
}

export default connect(mapStateToProps, { getData })(Controller);