import { combineReducers } from 'redux';
// import { RECIEVED_DATA } from './action';

const recieveData = (state, action) => {
  return { ...state, data: action.data || [] }
}

const setKey = (state, action) => {
  return { ...state, key: action.key }
}

const changeColor = (state, action) => {
  return { ...state, color: action.color }
}

const reducers = {
  RECIEVED_DATA: recieveData,
  CHANGE_COLOR: changeColor,
  CHANGE_KEY: setKey,
}

const initState = {};

export const apiReducer = (state = initState, action) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }

  return state;
}

export default combineReducers({
  api: apiReducer,
});
