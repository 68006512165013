import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ChromePicker } from 'react-color';
import { changeColor } from '../redux/action';

import axios from 'axios';

class ColorPicker extends Component {

  color = null;

  handleChange = (color, event) => {
    if (this.props.keyCell) {
      const elem = document.querySelector(`.${this.props.keyCell}`);
      this.color = color.hex;
      elem.style.backgroundColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    }
  }

  handleClick = () => {
    const normalizeColor = this.color.split('#')[1];
    const pos = this.props.keyCell.split('_');
    const posX = pos[1];
    const posY = pos[2];

    axios.get(`https://us-central1-protopixel-7163a.cloudfunctions.net/postPixel?x=${posX}&y=${posY}&color=${normalizeColor}`).then((res) => {
      console.log(res);
    })
  }
  

  render() {
    const { keyCell } = this.props;
    return (
      <div className={`color-picker-wrpaper ${keyCell ? 'active' : ''}`}>
        <ChromePicker color={{h: 0, s: 1, l: .5} } disableAlpha={true} onChange={this.handleChange} />
        <button className='btn-save' onClick={this.handleClick}>Save</button>
      </div >
    )
  }
}

const mapStateToProps = ({ api }) => {
  return {
    keyCell: api.key
  }
}

export default connect(mapStateToProps, { changeColor })(ColorPicker);