import './App.css';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Controller from './component/Controller';
import ColorPicker from './component/ColorPicker';

import { startWebSocket } from './redux/action';

class App extends Component {

  componentDidMount() {
    this.props.dispatch(startWebSocket(this.props.dispatch));
  }

  render() {
    return (
      <Fragment>
        <nav>
        </nav>
        <main style={{overflow: 'hidden'}}>
          <ColorPicker/>
          <div style={{ overflow: 'scroll'}}>
            <Controller/>
          </div>
        </main>
      </Fragment>
    );
  }
}

export default connect()(App);
